import logo from './logo.svg';
import './App.css';
import RouteStations from '../src/components/RouteStations'
import Navbar from './components/Navbar';

function App() {
  return (
    <>
    {/* <RouteStations/> */}
    <div style={{display:"flex",justifyContent:"center",height:"100vh",alignItems:"center",fontSize:"40px",backgroundColor:"#FFEED6"}}>
      <div style={{textAlign:"center"}}>We are under maintenance</div>
    </div>
    </>
  );
}

export default App;
